import debug from 'debug'
import Domain from './Domain'
import { AuthService } from '@remote.it/services'

const d = debug('r3:index')

//-----------------------------------------------------
// Set environment variables
//-----------------------------------------------------

window.API_URL = process.env.API_URL || 'https://api.remote.it/apv/v27'
window.ENVIRONMENT = process.env.NODE_ENV || 'development'
window.TASK_QUEUE_NAME = process.env.TASK_QUEUE_NAME || 'WeavedTaskQueue'
window.JOB_QUEUE_NAME = process.env.JOB_QUEUE_NAME || 'WeavedJobQueue'
window.isEnterprise = Domain.isEnterprise
window.DEVELOPER_KEY = process.env.DEVELOPER_KEY
window.LEGACY_EMAIL = process.env.LEGACY_EMAIL
window.PORTAL_URL = window.location.origin
window.GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS_ID
window.SEGMENT_KEY = process.env.SEGMENT_KEY
window.AIRBRAKE_PROJECT_KEY = '7cf55f197f817ce19e65d67d1c4d197f'
window.AIRBRAKE_PROJECT_ID = 205415

d('ENVIRONMENT:', window.ENVIRONMENT)
d('API_URL:', window.API_URL)
d('TASK_QUEUE_NAME:', window.TASK_QUEUE_NAME)
d('JOB_QUEUE_NAME:', window.JOB_QUEUE_NAME)
d('DEVELOPER_KEY:', window.DEVELOPER_KEY)
d('LEGACY_EMAIL:', window.LEGACY_EMAIL)
d('GOOGLE_ANALYTICS_ID:', window.GOOGLE_ANALYTICS_ID)
d('SEGMENT_KEY:', window.SEGMENT_KEY)
d('AIRBRAKE_PROJECT_KEY:', window.AIRBRAKE_PROJECT_KEY)
d('AIRBRAKE_PROJECT_ID:', window.AIRBRAKE_PROJECT_ID)

//-----------------------------------------------------
// Load AirBrake error reporting
//-----------------------------------------------------

const airbrake = new window.airbrakeJs.Client({
  environment: window.ENVIRONMENT,
  projectId: window.AIRBRAKE_PROJECT_ID,
  projectKey: window.AIRBRAKE_PROJECT_KEY,
})
airbrake.addFilter(function(notice) {
  console.log('FILTER', window.ENVIRONMENT)
  if (window.ENVIRONMENT === 'test') return null
  return notice
})

//-----------------------------------------------------
// Load Amplify
//-----------------------------------------------------

window.authService = new AuthService({
  cognitoClientID: process.env.COGNITO_CLIENT_ID,
  cognitoUserPoolID: process.env.COGNITO_USER_POOL_ID,
  cognitoAuthDomain: process.env.COGNITO_AUTH_DOMAIN,
  checkSamlURL: process.env.AUTH_API_URL + '/checkSaml',
  callbackURL: window.location.origin,
  signoutCallbackURL: window.location.origin,
})

//-----------------------------------------------------
// Load Google Analytics
//-----------------------------------------------------

window.dataLayer = window.dataLayer || []
function gtag(...args): void {
  window.dataLayer.push(...args)
}
gtag('js', new Date())
gtag('config', window.GOOGLE_ANALYTICS_ID)

//-----------------------------------------------------
// Load Segment Analytics
//-----------------------------------------------------
;(function(SEGEMENT_KEY) {
  const analytics = (window.analytics = window.analytics || [])
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.')
    else {
      analytics.invoked = !0
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ]
      analytics.factory = function(t) {
        return function(...args) {
          if (args.length < 1) return
          // @ts-ignore
          const e = Array.prototype.slice.call(...args)
          e.unshift(t)
          analytics.push(e)
          return analytics
        }
      }
      for (let t = 0; t < analytics.methods.length; t++) {
        const e = analytics.methods[t]
        analytics[e] = analytics.factory(e)
      }
      analytics.load = function(t, e) {
        const n = document.createElement('script')
        n.type = 'text/javascript'
        n.async = !0
        n.src =
          'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'
        const a = document.getElementsByTagName('script')[0]
        if (!a?.parentNode) return
        a.parentNode.insertBefore(n, a)
        analytics._loadOptions = e
      }
      analytics.SNIPPET_VERSION = '4.1.0'
      analytics.load(SEGEMENT_KEY)
    }
})(window.SEGMENT_KEY)
